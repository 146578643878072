<template>
  <div class="bitts-range-picker">
    <range-picker
      v-if="!useSingleDatePicker"
      separator="-"
      :allow-clear="false"
      :value="dateRange"
      :format="format"
      :disabled-date="disabledDate"
      :disabled="disabled"
      @change="onChange"
    >
      <template #suffixIcon>
        <div>
          <FontAwesomeIcon
            :icon="['far', 'calendar']"
            :style="{ height: '16px', width: '16px', color: 'currentColor' }"
            class="text-neutral-text-placeholder mr-4"
          />
        </div>
      </template>
    </range-picker>
    <date-picker
      v-else
      v-bind="dynamicProps()"
      :allow-clear="false"
      :autofocus="autoFocus"
      :value="date"
      :format="format"
      :disabled-date="disabledDate"
      :disabled="disabled"
      :placeholder="placeholder"
      @change="onChange"
    >
      <template #suffixIcon>
        <div>
          <FontAwesomeIcon
            :icon="['far', 'calendar']"
            :style="{ height: '16px', width: '16px', color: 'currentColor' }"
            class="text-neutral-text-placeholder mr-4"
          />
        </div>
      </template>
    </date-picker>
  </div>
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { DatePicker, RangePicker } from 'ant-design-vue';
import dayjs from 'dayjs';

export default {
  name: 'BittsRangePicker',
  components: {
    /* eslint-disable-next-line */
    DatePicker,
    RangePicker,
    FontAwesomeIcon,
  },
  props: {
    autoFocus: {
      type: Boolean,
      default: false,
    },
    disabledDate: {
      type: Function,
      required: false,
      default: () => false,
    },
    format: {
      type: String,
      default: 'YYYY/MM/DD',
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    placeholder: {
      type: String,
      default: 'Select a date',
    },
    initialDate: {
      type: [String, Object],
      default: '',
    },
    initialDateRange: {
      type: Array,
      required: false,
      default: () => [],
    },
    useOpen: {
      type: Boolean,
      default: false,
      required: false,
    },
    isOpen: {
      type: Boolean,
      default: false,
      required: false,
    },
    useSingleDatePicker: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['change'],
  data() {
    return {
      date: this.initialDate ? dayjs(this.initialDate, this.format) : '',
      dateRange: this.initialDateRange.map((d) => dayjs(d, this.format)),
    };
  },
  watch: {
    initialDate() {
      this.date = this.initialDate ? dayjs(this.initialDate, this.format) : '';
    },
  },
  methods: {
    onChange(dateInfo) {
      if (this.useSingleDatePicker) this.date = dateInfo;
      else this.dateRange = dateInfo;
      this.$emit('change', dateInfo);
    },
    dynamicProps() {
      const props = {};

      if (this.useOpen) {
        props.open = this.isOpen;
      }

      return props;
    },
  },
};
</script>

<style lang="pcss">
.ant-picker-dropdown {
  font-family: inherit;
}
.bitts-range-picker {
  .ant-picker {
    font-family: inherit;
  }
  .ant-calendar-picker-input {
    height: 40px;
    width: 240px;
    @apply border-2 border-neutral-background-disabled text-neutral-800 bg-white;
  }
  .ant-input-disabled {
    @apply bg-neutral-100;
  }
  .ant-calendar-range-picker-input {
    width: 40%;
  }
  .ant-calendar-range-picker-separator {
    vertical-align: unset;
  }
  .ant-calendar-picker-icon {
    top: 45%;
  }
}
</style>
